$color-grey-dark-1: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$navbar-color: navy;

$navlink--background-color: navy;
$navlink--color: gold;

$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;
