$card-height: 56rem;

.card_container {
  // background-color: red;
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.card {
  height: $card-height;
  // margin: auto;
  width: 35rem;
  position: relative;

  perspective: 150rem;

  &-1 {
    justify-self: start;
  }
  &-2 {
    margin: 0 auto;
  }
  &-3 {
    justify-self: end;
  }

  &__side {
    // height: 60rem;
    height: $card-height;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    backface-visibility: hidden;

    transition: all 1s ease;

    border-radius: 15px;
    box-shadow: 0 1rem 4rem rgba(black, 0.15);

    &--front {
      z-index: 2;
    }

    &--back {
      transform: rotateY(180deg);
      z-index: 1;
      &-1 {
        background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
      }

      &-2 {
        background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
      }

      &-3 {
        background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark);
      }
    }
  }

  .someText {
    opacity: 0;
    transition: all 1s ease;
  }

  .hover_activated_front {
    transform: rotateY(-180deg);
    z-index: 1;
  }
  .hover_activated_back {
    transform: rotateY(0);
    z-index: 2;
    // opacity: 1;

    .someText {
      opacity: 1;
    }
  }

  // &:hover &__side--front {
  //   transform: rotateY(-180deg);
  // }

  // &:hover &__side--back {
  //   transform: rotateY(0);
  // }

  // FRONT
  &__picture {
    height: 25rem;
    background-repeat: no-repeat;
    background-size: cover;

    background-blend-mode: screen;

    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);

    &--1 {
      background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark), url('../../media/4k furniture/a1.jpg');
      background-position: bottom;
    }

    &--2 {
      background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url('../../media/4k furniture/a2.jpg');
      background-position: center;
    }

    &--3 {
      background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark), url('../../media/4k furniture/a3.jpg');
      background-position: bottom;
    }
  }

  &__heading {
    position: absolute;
    text-transform: uppercase;
    top: 12rem;
    right: 10%;
    font-size: 2.8rem;
    font-weight: 300;
    width: 49%;
    text-align: right;
  }

  &__heading-span {
    padding: 1rem 1.5rem;
    color: #fff;
    -webkit-decoration-break: clone;
    box-decoration-break: clone;

    &--1 {
      background-image: (linear-gradient(to right bottom, rgba($color-secondary-light, 0.85), rgba($color-secondary-dark, 0.85)));
    }

    &--2 {
      background-image: (linear-gradient(to right bottom, rgba($color-primary-light, 0.85), rgba($color-primary-dark, 0.85)));
    }

    &--3 {
      background-image: (linear-gradient(to right bottom, rgba($color-tertiary-light, 0.85), rgba($color-tertiary-dark, 0.85)));
    }
  }

  &__details {
    padding: 3rem;

    &-1 {
      color: $color-secondary-dark;
    }

    &-2 {
      color: $color-primary-dark;
    }

    &-3 {
      color: $color-tertiary-dark;
    }

    ul {
      width: 80%;
      margin: 0 auto;

      li {
        text-align: center;
        padding: 1rem;
        font-size: 1.5rem;
        color: inherit;

        display: flex;
        justify-content: center;

        &:not(:last-child) {
          border-bottom: 1px solid $color-grey-light-2;
        }

        &:last-child {
          margin-top: 1rem;
        }
      }
    }
  }

  .svg-btn {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 0.5rem;
  }

  .svg-white {
    fill: white;
  }

  .svg-orange {
    fill: $color-secondary-dark;
  }

  .color-orange {
    color: $color-secondary-dark;
  }

  .svg-green {
    fill: $color-primary-dark;
  }

  .color-green {
    color: $color-primary-dark;
  }

  .svg-blue {
    fill: $color-tertiary-dark;
  }

  .color-blue {
    color: $color-tertiary-dark;
  }

  // Front END

  // BACK
  &__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }

  &__price-box {
    text-align: center;
    margin-bottom: 8rem;
  }

  &__price-only {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: white;
  }

  &__price-value {
    font-size: 6rem;
    font-weight: 100;
    color: white;
  }

  &__back-button {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
}
