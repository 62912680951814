.footer {
  background-color: $navbar-color;

  &--information {
    display: flex;
    padding: 3rem 2rem;
    // justify-content: space-around;
    justify-content: space-between;
  }

  &--hour_title,
  &--contact_title,
  &--social_title,
  &--language_title {
    font-size: 3rem;
    color: $color-grey-light-1;
    font-weight: 400;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
    display: inline-block;
    line-height: 1.4;
    border-bottom: 1px solid rgba($color-grey-light-1, 0.6);
  }

  &--item {
    font-size: 1.5rem;
    display: flex;
    width: 25rem;
    justify-content: space-between;
    color: $color-grey-light-2;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgba($color-grey-light-2, 0.15);

    span {
      color: currentColor;
    }
  }

  &--address {
    font-size: 1.5rem;
  }

  &--phone__icon,
  &--map_icon,
  &--direction_icon {
    height: 2rem;
    width: 2rem;
    fill: $color-grey-light-1;
    margin-right: 0.5rem;
  }

  &--icon-href {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &--social_facebook {
    fill: $color-grey-light-1;
    height: 2rem;
    width: 2rem;
    margin-top: 0.35rem;
    display: block;
  }

  &--language {
    margin-top: 2rem;
  }

  &--disclaimer {
    text-align: center;

    background-color: lighten($navbar-color, 2%);
    color: darken($color-grey-light-2, 25%);
  }

  &--disclaimer_title {
    font-family: 'Roboto', sans-serif;
    font-size: 3rem;
    letter-spacing: -0.15rem;
    padding-top: 3rem;
    padding-bottom: 2rem;

    border-top: 1px solid rgba($color-grey-light-1, 0.2);
  }

  &--disclaimer_paragraph {
    font-size: 1.2rem;
    letter-spacing: 0.12rem;
    padding-bottom: 3rem;
  }
}
