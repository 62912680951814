.category {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  padding: 3rem;

  &--container {
    background-color: $color-grey-light-1;
  }

  &--color {
    background-color: $navbar-color;
    padding-bottom: 8rem;
  }

  &--title {
    font-size: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: $color-grey-light-1;
    text-align: center;
    letter-spacing: 0.1rem;
    background-color: lighten($navbar-color, 2%);
  }

  &--item {
    padding: 2rem;
    display: flex;
    flex-direction: column;

    border: 1px solid darken($color-grey-light-2, 5%);
    transition: background-color 0.6s ease-in;

    &:hover {
      transition: all 0.6s ease-in;
      background-color: darken($color-grey-light-1, 10%);
      border: 1px solid darken($color-grey-light-2, 10%);
      transform: scale(1.05);
    }
  }

  &--item_title {
    text-align: center;
    transition: all 0.6s ease-in;
    color: $navbar-color;
  }

  &--item:hover &--item_title {
    color: lighten($navbar-color, 15%);
  }

  &--image {
    align-self: center;
    height: 20rem;
    width: 20rem;
    // display: none;
  }
}
