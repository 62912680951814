*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  color: rgb(194, 194, 194);
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

img {
  width: 100%;
  display: block;
}
