.showcase {
  &--container {
    // width: 50px !important;
    // padding-bottom: 8rem;
    position: relative;
  }

  & > * {
    user-select: none;
  }

  &--img_grid {
    overflow: hidden;
    position: relative;
    height: 63.8rem;
  }

  &--img_mover {
    display: grid;
    grid-template-columns: repeat(9, 100%);
  }

  &--img {
    position: absolute;
    top: 0;
    left: -100%;
    transition: left 1s ease;
  }

  &--icon_div_left,
  &--icon_div_right {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    display: flex;
  }

  &--icon_div_left {
    left: 0;
  }

  &--icon_div_right {
    right: 0;
  }

  &--icon_left,
  &--icon_right {
    height: 6rem;
    width: 6rem;
    fill: lighten($navbar-color, 20%);
    background-color: rgba(white, 0.2);
    cursor: pointer;
    border-radius: 50%;
    transition: all 1s ease;

    &:hover {
      fill: lighten($navbar-color, 35%);
    }
  }

  &--icon_div_left,
  &--icon_div_right {
    transition: all 0.2s;

    &:hover {
      transform: translateY(calc(-50% - 3px));
    }

    &:active {
      outline: none;
      transform: translateY(calc(-50% + 2px));
    }

    &:focus {
      outline: none;
    }
  }

  &--circle_group {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
    justify-content: center;
  }

  &--circle_icon {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 50%;
    margin: 0 0.1rem 0.5rem 0.1rem;

    background-color: rgba(black, 0.5);
    fill: rgba(white, 0.5);
    cursor: pointer;

    transition: all 1s ease;
  }
}

.after_effect {
  .showcase--icon_left {
    animation: showcase-move-left 0.8s linear;
    box-shadow: 0 1rem 2rem rgba(black, 0.15);
  }
  .showcase--icon_right {
    animation: showcase-move-right 0.8s linear;
    box-shadow: 0 1rem 2rem rgba(black, 0.15);
  }
}

@keyframes showcase-move-right {
  0% {
    background-color: white;
    transform: translateX(-1rem);
  }

  100% {
    background-color: white;
  }
}

@keyframes showcase-move-left {
  0% {
    background-color: white;
    transform: translateX(1rem);
  }

  100% {
    background-color: white;
  }
}
