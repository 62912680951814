// .translate-X0 {
//   transform: translateX(0%);
// }
// .translate-X1 {
//   transform: translateX(-100%);
// }
// .translate-X2 {
//   transform: translateX(-200%);
// }
// .translate-X3 {
//   transform: translateX(-300%);
// }
// .translate-X4 {
//   transform: translateX(-400%);
// }
// .translate-X5 {
//   transform: translateX(-500%);
// }
// .translate-X6 {
//   transform: translateX(-600%);
// }
// .translate-X7 {
//   transform: translateX(-700%);
// }
// .translate-X8 {
//   transform: translateX(-800%);
// }

.transition-none {
  transition: none !important;
}

.transition-1s {
  transition: all 1s ease;
}
