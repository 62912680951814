.btn {
  text-transform: uppercase;
  display: inline-block;

  transition: all 0.2s ease;
  position: relative;

  border: none;
  cursor: pointer;
  color: #fff;

  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    outline: none;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(black, 0.2);

    &::after {
      transform: scaleX(1.4) scale(1.6);
      opacity: 0;
    }
  }

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    transition: all 0.4s;
  }

  &:active {
    transform: translateY(2px);
  }

  &-medium {
    padding: 1.5rem 4rem;
    border-radius: 10rem;

    &::after {
      border-radius: 10rem;
    }
  }

  &-small {
    padding: 0.75rem 2rem;
    border-radius: 5rem;

    &::after {
      border-radius: 5rem;
    }
  }

  &-orange {
    background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
    &::after {
      background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
    }
  }

  &-green {
    background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
    &::after {
      background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
    }
  }

  &-blue {
    background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark);
    &::after {
      background-image: linear-gradient(to right bottom, $color-tertiary-light, $color-tertiary-dark);
    }
  }

  &-white {
    background-color: #fff;
    &::after {
      background-color: white;
    }
  }
}
