.map {
  &--container {
    display: flex;
    justify-content: center;
  }

  &--iframe {
    flex: 0 0 100%;
    height: 60rem;
  }
}
