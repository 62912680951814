.navigation {
  background-color: lighten($navbar-color, 2%);
  // background-color: transparent;
  // border-bottom: 2px solid rgba($color-grey-light-2, 0.1);
  position: fixed;
  width: 100%;
  z-index: 100;

  &--container {
    display: flex;
    justify-content: space-between;
    background-color: $navbar-color;
  }

  &--logo {
    display: flex;
    align-items: center;

    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    letter-spacing: -0.15rem;
  }

  &--list {
    display: flex;
  }

  &--link {
    font-family: 'Roboto', sans-serif;
    padding: 2rem 2rem;
    height: 100%;
    font-size: 1.5rem;

    display: flex;
    align-items: center;
  }

  &--item {
    transition: all 1s ease;
    border: 1px solid transparent;
    &:hover {
      background-color: lighten($navbar-color, 15%);
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
    &:hover .navigation--link {
      color: lighten($navlink--color, 50%);
    }
  }

  &--fix {
    // background: red;
    height: 6.7rem;
  }

  &--category_icon {
    height: 2rem;
    width: 2rem;
    margin-left: 0.25rem;
    fill: white;
  }

  &--category_container {
    position: relative;
  }

  &--category_container:hover &--category_div {
    // animation: solarmation 1s ease;
    // animation-fill-mode: forwards;
  }

  &--category_div {
    position: absolute;
    top: 100%;
    left: -100%;

    background: #fff;
    display: grid;

    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #eee;
    box-shadow: 0 1rem 2rem rgba(black, 0.4);

    padding: 1rem 0rem;

    opacity: 0;
    transform: rotateY(89.5deg) translateY(-100rem);
  }

  &--category_div {
    grid-template-columns: repeat(2, 1fr);
  }

  &--category_item {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;

    white-space: nowrap;
    margin: 0.5rem 5rem;
    background: #fff;
    letter-spacing: 0.1rem;

    &:nth-child(even) {
      span {
        &::after {
          transform: translateX(-50%);
        }
      }
    }

    &:nth-child(odd) {
      span {
        &::after {
          transform: translateX(50%);
        }
      }
    }

    span {
      color: $color-grey-dark-2;
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        left: 0;
        bottom: -20%;
        background-color: lighten($navbar-color, 30%);
        opacity: 0;
        transition: all 0.2s ease;
      }
      &:hover {
        color: lighten($navbar-color, 30%);
        transition: all 0.2s ease;
        &::after {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  &--category_clicked {
    animation: solarmation 1s ease;
    animation-fill-mode: forwards;
  }

  @keyframes solarmation {
    0% {
    }

    33% {
      transform: rotateY(89.5deg) translateY(0rem);
      opacity: 0.25;
    }

    100% {
      transform: rotateY(0deg) translateY(0rem);
      opacity: 1;
    }
  }
}
